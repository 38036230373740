import type { JWTPayload as BasicJWTPayload } from "jose";
import { decodeJwt } from "jose";
import { refresh as refreshCall, login as loginCall } from "~/lib/api/auth";
import axios from "axios";

export type JWTPayload = BasicJWTPayload & {
    prv: string;
    app_id: number;
    user_id: number;
    is_owner: boolean;
    is_admin: boolean;
    permissions: string[];
    login_url: string;
};

axios.defaults.headers.common = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
};
const oneMinute = 60 * 1000;

export const useTokenStore = defineStore("tokenStore", () => {
    const token = useLocalStorage<string | undefined>(
        "tokenStore-token",
        undefined,
    );

    const refreshToken = useLocalStorage<string | undefined>(
        "tokenStore-refreshToken",
        undefined,
    );

    const refreshTokenTtl = useLocalStorage<string | undefined>(
        "tokenStore-refreshTokenTtl",
        undefined,
    );
    const { locale } = storeToRefs(useLocalSettingsStore());

    const config = useRuntimeConfig();
    const baseUrl = computed(() => `${config.public.masterApiBaseUrl}/`);
    watchEffect(() => {
        axios.defaults.baseURL = baseUrl.value;
    });
    const payload = computed(() => {
        if (!token.value) return undefined;

        try {
            return decodeJwt<JWTPayload>(token.value);
        } catch (e) {
            return undefined;
        }
    });

    function tokenExpired() {
        return payload.value?.exp
            ? payload.value.exp * 1000 - Date.now() - oneMinute < 0
            : true;
    }
    function refreshTokenExpired() {
        return refreshTokenTtl.value
            ? new Date(refreshTokenTtl.value).getTime() - Date.now() < 0
            : false;
    }

    async function logout() {
        setToken({});

        await navigateTo({ name: "login" });
    }

    const timer = ref<NodeJS.Timeout>();

    function startRefreshTokenTimer() {
        if (timer.value) clearTimeout(timer.value);

        if (!payload.value?.exp) {
            throw new Error('"exp" not set in JWT!');
        }
        const timeout = payload.value?.exp * 1000 - Date.now() - oneMinute;
        timer.value = setTimeout(() => {
            refresh().catch((error) => console.log(error));
        }, timeout);
    }

    function setToken(tokenData: {
        token?: string;
        refreshToken?: string;
        refreshTokenTtl?: string;
        locale?: string;
    }) {
        token.value = tokenData.token;
        refreshToken.value = tokenData.refreshToken;
        refreshTokenTtl.value = tokenData.refreshTokenTtl;
        axios.defaults.headers.common.Authorization = `Bearer ${token.value}`;
        if (tokenData.locale) {
            locale.value = tokenData.locale;
            axios.defaults.headers.common["Accept-Language"] = tokenData.locale;
        }

        if (token.value && payload.value?.exp) startRefreshTokenTimer();
        else if (timer.value) clearTimeout(timer.value);
    }
    async function refresh() {
        if (refreshToken.value) {
            const refreshResponse = await refreshCall(refreshToken.value);
            setToken({
                token: refreshResponse.access_token,
                refreshToken: refreshResponse.refresh_token,
                refreshTokenTtl: refreshResponse.refresh_token_ttl,
            });
            return;
        }
        throw new Error("No Refresh Token available");
    }

    async function login(
        email: string,
        password: string,
        intendedRoute?: string,
    ) {
        const loginData = await loginCall({ email, password });

        if (
            loginData.access_token &&
            loginData.refresh_token &&
            loginData.refresh_token_ttl
        ) {
            setToken({
                token: loginData.access_token,
                refreshToken: loginData.refresh_token,
                refreshTokenTtl: loginData.refresh_token_ttl,
            });

            await navigateTo({
                path: intendedRoute ? intendedRoute : `/dashboard`,
            });
        } else {
            console.log("Login was correct but no Token was received");
        }
    }

    setToken({
        token: token.value,
        refreshToken: refreshToken.value,
        locale: locale.value,
        refreshTokenTtl: refreshTokenTtl.value,
    });

    return {
        token: readonly(token),
        refreshToken: readonly(refreshToken),
        payload: readonly(payload),
        login,
        logout,
        tokenExpired,
        refreshTokenExpired,
        refresh,
        setToken,
    };
});
