import { default as dashboardhk5ZgexNYOMeta } from "/home/forge/master.smake.construction/releases/v1.1.0/pages/dashboard.vue?macro=true";
import { default as entranceJg9cC3jcO6Meta } from "/home/forge/master.smake.construction/releases/v1.1.0/pages/entrance.vue?macro=true";
import { default as index3iYzzzt0yFMeta } from "/home/forge/master.smake.construction/releases/v1.1.0/pages/index.vue?macro=true";
import { default as login3mVMU8pwU1Meta } from "/home/forge/master.smake.construction/releases/v1.1.0/pages/login.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/forge/master.smake.construction/releases/v1.1.0/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: entranceJg9cC3jcO6Meta?.name ?? "entrance",
    path: "/entrance",
    meta: entranceJg9cC3jcO6Meta || {},
    component: () => import("/home/forge/master.smake.construction/releases/v1.1.0/pages/entrance.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/master.smake.construction/releases/v1.1.0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login3mVMU8pwU1Meta?.name ?? "login",
    path: "/login",
    meta: login3mVMU8pwU1Meta || {},
    component: () => import("/home/forge/master.smake.construction/releases/v1.1.0/pages/login.vue").then(m => m.default || m)
  }
]