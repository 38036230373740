/**
 * Ensures user is authenticated
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const tokenStore = useTokenStore();
    const { token } = storeToRefs(tokenStore);

    if (isGuestPage(to)) return;

    // Check Token
    const tokenExpired = tokenStore.tokenExpired();
    const refreshTokenExpired = tokenStore.refreshTokenExpired();

    if (!token.value || (tokenExpired && refreshTokenExpired)) {
        return navigateTo({
            name: "login",
            ...(!is404Page(to) &&
                !to.path.match("/$") && { query: { redirect: to.fullPath } }),
        });
    } else if (tokenExpired && !refreshTokenExpired) {
        try {
            await tokenStore.refresh();
        } catch {
            tokenStore.setToken({});

            return navigateTo({
                name: "login",
                ...(!is404Page(to) && { query: { redirect: to.fullPath } }),
            });
        }
    }

    // Check User
    const userStore = useUserStore();
    const { isError } = storeToRefs(userStore);
    await userStore.suspense();
    if (isError.value) {
        return showError({
            statusCode: 404,
        });
    }
});
